import React, { useState, useEffect, useLayoutEffect, useRef } from "react"
import { graphql, Link } from "gatsby"
import styled from "styled-components"
import appStoreImage from "../images/app-store-download.jpeg"
import { useGoal } from "gatsby-plugin-fathom"

import Metadata from "../components/Metadata"
import EquipmentThumbnail from "../components/EquipmentThumbnail"
import InfoPill from "../components/InfoPill"
import ExerciseEmbed from "../components/ExerciseEmbed"
import ExerciseThumbnail from "../components/ExerciseThumbnail"
import FooterPitch from "../components/FooterPitch"
import { extractText, handlePlugin } from "./resource"

export const pageQuery = graphql`
  query ($id: ID!) {
    programme {
      movement(id: $id) {
        id
        name
        cues
        type
        category
        target
        bilateral
        level
        thumbnail_url
        video_url
        equipment {
          name
        }
        volume_bound_upper
        volume_bound_lower
        volume_unit
        intensity_multiplier
        moreCategoryMovements {
          id
          name
          category
          type
          target
          thumbnail_url
        }
        moreTargetMovements {
          id
          name
          category
          type
          target
          thumbnail_url
        }
        moreTypeMovements {
          id
          name
          category
          type
          target
          thumbnail_url
        }
      }
    }
  }
`

const Exercise = ({
  data: {
    programme: { movement },
  },
  pageContext: { slug, content: c, blocks },
  location,
}) => {
  const {
    thumbnail_url,
    volume_bound_upper,
    volume_bound_lower,
    volume_unit,
    intensity_multiplier,
    video_url,
    name,
    cues,
    type,
    target,
    category,
    equipment,
    moreTypeMovements,
    moreTargetMovements,
    moreCategoryMovements,
  } = movement
  const volumeRange = volume_bound_upper - volume_bound_lower
  const [embedHeight, setEmbedHeight] = useState()
  const [embedButtonText, setEmbedButtonText] = useState("Copy Embed Code")
  const embed = useRef()
  const embedCode = useRef()
  const handleGoal = useGoal("OAUGRCJK")

  const summary = extractText(c?.summary[c.summary.type])
  const benefits = extractText(c?.benefits[c.benefits.type])
  const common_mistakes = extractText(
    c?.common_mistakes[c.common_mistakes.type]
  )
  const instructions = extractText(c?.instructions[c.instructions.type])
  const programming = extractText(c?.programming[c.programming.type])
  const variables = extractText(c?.variables[c.variables.type])

  useEffect(() => {
    const video = document.getElementById(`video-hero`)
    if (video) {
      video.muted = true
      video.setAttribute("playsinline", true)
      video.setAttribute("autoplay", true)
    }
  }, [])

  const onClick = () => {
    const video = document.getElementById(`video-hero`)
    var playPromise = video.play()

    if (playPromise !== undefined) {
      playPromise.then(_ => {}).catch(error => {})
    }
  }

  useLayoutEffect(() => {
    if (!inIframe()) setEmbedHeight(embed?.current?.clientHeight)
  }, [embed])

  const copyToClipboard = e => {
    embedCode.current.select()
    document.execCommand("copy")
    embedCode.current.blur()
    setEmbedButtonText("Copied 👍")
    setTimeout(() => setEmbedButtonText("Copy Embed Code"), 1000)
  }

  function inIframe() {
    try {
      return window.self !== window.top
    } catch (e) {
      return true
    }
  }

  return inIframe() ? (
    <div>
      <Metadata
        title={`${movement.name} - Video, Instructions & Variations`}
        url={`https://programme.app/${slug}`}
        description={`Learn the correct form to complete the ${movement.name} exercise. Embed ${movement.name} into any website.`}
        image={movement.thumbnail_url}
        video={movement.video_url}
      />
      <EmbedContainer>
        <ExerciseEmbed {...movement} inIframe={inIframe()} />
      </EmbedContainer>
    </div>
  ) : (
    <Container>
      <Metadata
        title={`${movement.name} - Video, Instructions & Variations`}
        url={`https://programme.app/${slug}`}
        description={`Learn the correct form to complete the ${movement.name} exercise. Embed ${movement.name} into any website.`}
        image={movement.thumbnail_url}
        video={movement.video_url}
      />
      <Video
        id="video-hero"
        poster={thumbnail_url}
        autoplay
        loop
        controls={true}
        onClick={onClick}
      >
        <source src={video_url} type="video/mp4" />
      </Video>
      <Section style={{ paddingBottom: "12em" }}>
        <Content>
          <h1>{name}</h1>

          <div>
            <InfoPill first borderColor="#F7F5F2">
              {type}
            </InfoPill>
            <InfoPill borderColor="#F7F5F2">{target}</InfoPill>
            <InfoPill last borderColor="#F7F5F2">
              {category || "Not Set"}
            </InfoPill>
          </div>

          {summary && (
            <>
              <h3 id="summary">Summary</h3>
              <p>{summary}</p>
            </>
          )}

          {benefits && (
            <>
              <h3 id="benefits">Benefits of {name}</h3>
              <p>{benefits}</p>
            </>
          )}

          {instructions && (
            <>
              <h3 id="instructions">Instructions</h3>
              <p>
                <ol>
                  {instructions
                    .split(/[123456789]. /)
                    .slice(1)
                    .map(i => (
                      <li key={i}>{i}</li>
                    ))}
                </ol>
              </p>
            </>
          )}

          <h3 id="coaching-tips">Coaching Tips for {name}</h3>
          <p>Follow these cues to perform {name} correctly:</p>
          <ul>
            {cues.split(". ").map(cue => (
              <li>{cue}</li>
            ))}
          </ul>

          {variables && (
            <>
              <h3 id="variables">Sets & Repetitions</h3>
              <p>{variables}</p>
            </>
          )}

          <Table>
            <tbody>
              <tr>
                <td>Beginner</td>
                <td>
                  {volume_bound_lower} {volume_unit === "s" && "Seconds"}
                  {volume_unit === "m" && "Meters"}
                  {volume_unit === "x" && "Reps"}
                </td>
                <td>3 Sets</td>
                {intensity_multiplier && (
                  <td>
                    {Math.ceil((intensity_multiplier * 1 * 2.2) / 5) * 5}lbs
                  </td>
                )}
              </tr>
              <tr>
                <td>Intermediate</td>
                <td>
                  {Math.ceil((volume_bound_lower + volumeRange * 0.4) / 2) * 2}{" "}
                  {volume_unit === "s" && "Seconds"}
                  {volume_unit === "m" && "Meters"}
                  {volume_unit === "x" && "Reps"}
                </td>
                <td>4 Sets</td>
                {intensity_multiplier && (
                  <td>
                    {Math.ceil((intensity_multiplier * 2.5 * 2.2) / 5) * 5}
                    lbs
                  </td>
                )}
              </tr>
              <tr>
                <td>Advanced</td>
                <td>
                  {Math.ceil((volume_bound_lower + volumeRange * 0.8) / 2) * 2}{" "}
                  {volume_unit === "s" && "Seconds"}
                  {volume_unit === "m" && "Meters"}
                  {volume_unit === "x" && "Reps"}
                </td>
                <td>4 Sets</td>
                {intensity_multiplier && (
                  <td>
                    {Math.ceil((intensity_multiplier * 4 * 2.2) / 5) * 5}lbs
                  </td>
                )}
              </tr>
            </tbody>
          </Table>

          {programming && (
            <>
              <h3 id="programming-considerations">
                How To Add {name} Into Your Programme
              </h3>
              <p>{programming}</p>
            </>
          )}

          {common_mistakes && (
            <>
              <h3 id="common-mistakes">Common Mistakes</h3>
              <p>
                <ol>
                  {common_mistakes
                    .split(/[123456789]. /)
                    .slice(1)
                    .map(i => (
                      <li key={i}>{i}</li>
                    ))}
                </ol>
              </p>
            </>
          )}

          {equipment && equipment.length ? (
            <>
              <h3 id="equipment">Equipment</h3>
              <div style={{ display: "flex", flexDirection: "horizontal", overflow: "visible", flexWrap: "wrap" }}>
                {equipment.map(e => (
                  <EquipmentThumbnail {...e} />
                ))}
              </div>
            </>
          ) : null}

          <h3 id="preview">Embed A Summary On Your Website</h3>
          <PreviewContainer ref={embed}>
            <ExerciseEmbed {...movement} />
          </PreviewContainer>

          {blocks && blocks.length ? (
            <Body>
              {blocks.map(block => {
                const text = extractText(block[block.type].text, block.type)

                switch (block.type) {
                  case "heading_2":
                    return (
                      <h2
                        id={text.toLowerCase().replace(/ /g, "-")}
                        dangerouslySetInnerHTML={{ __html: text }}
                      />
                    )
                  case "heading_3":
                    return (
                      <h3
                        id={text.toLowerCase().replace(/ /g, "-")}
                        dangerouslySetInnerHTML={{ __html: text }}
                      />
                    )
                  case "paragraph":
                    return <p dangerouslySetInnerHTML={{ __html: text }} />
                  case "bulleted_list_item":
                  case "numbered_list_item":
                    return <li dangerouslySetInnerHTML={{ __html: text }} />
                  case "code":
                    return handlePlugin(text)
                  default:
                    return <p dangerouslySetInnerHTML={{ __html: text }} />
                }
              })}
            </Body>
          ) : null}
        </Content>
        <Sidebar>
          <SidebarContainer>
            <NumberedHeading>
              <div>1</div>
              <h5>Embed This Exercise</h5>
            </NumberedHeading>
            <div>
              Embed {name} in an article or blog post under our{" "}
              <Link to="/license">free-to-use license.</Link>
            </div>
            <EmbedCode
              ref={embedCode}
              value={`<iframe title="${name}" src="${
                location.origin + location.pathname
              }" width="100%" height="${
                embedHeight + 40 + "px"
              }" frameborder="no" scrolling="no" border="0" allowTransparency allowfullscreen />`}
            />
            <Button onClick={copyToClipboard}>{embedButtonText}</Button>
          </SidebarContainer>
          <SidebarContainer>
            <NumberedHeading>
              <div>2</div>
              <h5>Share This Exercise</h5>
            </NumberedHeading>
            <div>
              Share {name} on social media or with a personal training client.
            </div>
            <SocialLink
              href={`http://twitter.com/share?text=${name.replace(
                / /g,
                "%20"
              )}&url=${location.href}`}
            >
              Twitter
            </SocialLink>
            <SocialLink
              href={`https://www.linkedin.com/sharing/share-offsite/?url=${location.href}`}
            >
              LinkedIn
            </SocialLink>
            <SocialLink
              href={`https://www.facebook.com/sharer/sharer.php?u=${
                location.href
              }&t=${name.replace(/ /g, "%20")}`}
            >
              Facebook
            </SocialLink>
            <SocialLink
              href={`mailto:?body=${name.replace(/ /g, "%20")}%0A${
                location.href
              }&subject=${name.replace(/ /g, "%20")}`}
            >
              Email
            </SocialLink>
          </SidebarContainer>
          <SidebarContainer>
            <NumberedHeading>
              <div>3</div>
              <h5>Download The App</h5>
            </NumberedHeading>
            <div>
              Get our entire movement library in a workout programme tailored to
              you.
            </div>
            <a
              onClick={() => handleGoal("OAUGRCJK")}
              href="https://apps.apple.com/us/app/programme/id1554594236"
            >
              <Download alt="Download on the App Store" src={appStoreImage} />
            </a>
          </SidebarContainer>
        </Sidebar>
      </Section>
      <Section background="var(--black)" style={{ paddingTop: "4em" }}>
        <MovementRows>
          <NumberedHeading dark>
            <div>1</div>
            <h5>More {category} Exercises</h5>
          </NumberedHeading>
          <MovementRow>
            {moreCategoryMovements.map(m => (
              <ExerciseThumbnail {...m} />
            ))}
          </MovementRow>

          <NumberedHeading dark>
            <div>2</div>
            <h5>More {target} Exercises</h5>
          </NumberedHeading>
          <MovementRow>
            {moreTargetMovements.map(m => (
              <ExerciseThumbnail {...m} />
            ))}
          </MovementRow>

          <NumberedHeading dark>
            <div>3</div>
            <h5>More {type} Exercises</h5>
          </NumberedHeading>
          <MovementRow>
            {moreTypeMovements.map(m => (
              <ExerciseThumbnail {...m} />
            ))}
          </MovementRow>
        </MovementRows>
      </Section>
      <Section background="var(--black)">
        <FooterPitch />
      </Section>
    </Container>
  )
}

const Container = styled.div`
  background: var(--beige);
`

const Section = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding-top: 2em;
  width: 100%;
  background: ${({ background }) => background};

  @media (max-width: 960px) {
    flex-direction: column;
    padding-top: 30px;
  }
`

const Video = styled.video`
  width: 100%;
  max-width: 100%;
  max-height: 80vh;
  margin: 0 auto;
  background: var(--black);
`

const Content = styled.div`
  width: 640px;

  h1 {
    margin-block-start: 0;
  }

  @media (max-width: 960px) {
    width: 100%;
    padding: 0 20px;
  }
`

const Sidebar = styled.div`
  width: 260px;
  margin-left: 60px;
  position: sticky;
  top: 40px;
  height: 500px;

  @media (max-width: 960px) {
    width: 100%;
    padding: 20px;
    margin-top: 40px;
    margin-left: 0;
    position: static;
    height: auto;
  }
`

const SidebarContainer = styled.div`
  border-top: 1px solid var(--beige-offset);
  background: var(--off-white);
  padding: 16px 20px 20px;
  color: var(--grey);

  &:first-child {
    border-top: none;
  }
`

const Table = styled.table`
  width: 100%;

  td {
    background: var(--off-white);
    padding: 4px 8px;
    text-align: right;
  }

  td:first-child {
    text-align: left;
  }

  tr:nth-child(2n) {
    td {
      background: var(--beige-offset);
    }
  }
`

const Body = styled.div`
  margin: 2em auto;

  > h1,
  > h2,
  > h3,
  > p,
  > ul,
  > ol,
  > li {
    max-width: 640px !important;
    margin-left: auto;
    margin-right: auto;
    color: var(--black);
  }

  p,
  li {
    color: var(--grey);
  }

  a {
    color: var(--black);
    text-decoration: underline;
  }
`

const NumberedHeading = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 5px;
  div:first-child {
    width: 15px;
    height: 15px;
    font-size: 12px;
    font-weight: bold;
    line-height: 18px;
    background: ${({ dark }) => (dark ? "var(--white)" : "var(--black)")};
    color: ${({ dark }) => (dark ? "var(--black)" : "var(--white)")};
    border-radius: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding-top: 1px;
  }
  h5 {
    margin-left: 5px;
    padding-top: 2px;
    color: ${({ dark }) => (dark ? "var(--white)" : "var(--black)")};
  }
`

const EmbedCode = styled.input`
  overflow-x: scroll;
  padding: 5px 10px;
  white-space: nowrap;
  height: 38px;
  background: #eee;
  outline: none;
  border: none;
  width: 100%;
  margin: 10px 0;
`

const Button = styled.div`
  background: var(--blue);
  border-radius: 30px;
  height: 32px;
  color: white;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin: 0 auto !important;
`

const SocialLink = styled.a`
  text-decoration: none;
  color: black;
  background: #eee;
  height: 32px;
  margin-right: 5px;
  margin-top: 5px;
  display: inline-block;
  padding: 3px 10px;
  border-radius: 20px;
  line-height: 26px;
`

const PreviewContainer = styled.div`
  padding: 0;
  max-width: 640px;
  margin: 0 auto;
  border-radius: 20px;
  box-shadow: 0px 2px 4px 0px #0000000d;
  width: 100%;
  background: #fbfaf9;
  overflow: hidden;
`

const MovementRows = styled.div`
  width: 100%;
  padding-left: 2em;

  @media (max-width: 960px) {
    padding-left: 1em;
    margin-top: 40px;
  }
`

const MovementRow = styled.div`
  overflow-y: scroll;
  display: flex;
  flex-direction: row;
  padding-bottom: 2em;

  & > div {
    margin: 5px 10px 5px 5px;
    min-width: calc(30% - 10px);
    max-width: calc(30% - 10px);
  }

  @media (max-width: 960px) {
    & > div {
      margin: 5px 10px 5px 5px;
      min-width: calc(90% - 10px);
      max-width: calc(90% - 10px);
    }
  }
`

const Download = styled.img`
  height: 40px;
  margin-top: 15px;
  margin-bottom: 0;
`

const EmbedContainer = styled.div`
  padding: 0;
  max-width: 640px;
  margin: 10px auto;
  border-radius: 20px;
  box-shadow: 0px 2px 4px 0px #0000000d;
  height: 100%;
  width: 100%;
  background: #fbfaf9;
  overflow: hidden;
`

export default Exercise
